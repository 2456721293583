.sidewords {
    color: var(--accent-color);
    position: absolute;
    transform: rotate(90deg);
    transform-origin: 0 20px;
    top: -20px;
    left: 20px;
    font-weight: lighter;
    width: max-content;
}

.wordlist {
    list-style: none;
    margin: 0;
    padding: 0;
}

.wordlist-item {
    display: inline-block;
    margin-right: 1em;
    color: var(--accent-color);
    transition: color linear .5s;
}

.wordlist-item.current {
    color: var(--text-color);
}

.wordlist-item::after {
    padding-left: 1em;
    content: "-";
    color: var(--accent-color);
}

.wordlist-item:last-child::after {
    content: "";
}
