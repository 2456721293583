.cv-button {
    display: inline-block;
    padding: 0.8em;
    font-weight: 700;
    color: var(--text-color);
    background-color: var(--light-color);
    border-radius: 4px;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 1em;
}

.cv-button::before {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 0%;
    content: "";
    background-color: rgba(255, 255, 255, 0.15);
    transition: width ease-in-out 0.4s;
}

.cv-button:hover::before {
    width: 100%;
    transition: width ease-in-out 1.2s;
}

.cv-button > * {
    z-index: 3;
    position: relative;
}

.btn-act {
    background-color: var(--accent-color);
}
