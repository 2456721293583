:root {
    --bg-color: #241e25;
    --text-color: #fff;
    --accent-color: #e66d43;
    --light-color: rgba(255, 255, 255, 0.2);
}

body,
#root,
.App {
    position: relative;
}

#root {
    overflow: hidden;
}

body {
    background-color: var(--bg-color);
    color: var(--text-color);
    font-weight: 500;
    position: relative;
}

body::after {
    z-index: 1;
    content: "";
    width: 80%;
    height: 100%;
    top: 0;
    right: 0;
    display: block;
    position: absolute;
    background-image: url(../public/img/dreams.jpg);
    background-position: 0% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    opacity: 0.4;
}

body > * {
    z-index: 2;
}

.main {
    margin: 20px 40px 20px 80px;
    border-top: 1px solid var(--accent-color);
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 40px);
}

.myname {
    font-size: 3.4em;
    font-weight: 800;
}

.intro {
    font-size: 1.8em;
    font-weight: 500;
    max-width: 960px;
}

.intro strong {
    font-weight: initial;
    color: var(--accent-color);
}

@media (min-width: 600px) {
    .main {
        font-size: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .main {
        font-size: 1.5rem;
    }
}

.skills-contact {
    flex-grow: 2;
}

.skills {
    font-weight: 100;
    list-style: none;
    margin: 5em 0 2em;
    padding: 0;
}

.skill-item {
    margin: 0 5px 0;
    padding: 0;
    display: inline-block;
}

.contact {
    display: flex;
    column-gap: 1em;
    flex-direction: column;
    max-width: 900px;
    margin: 1em 0 4em;
    align-items: baseline;
}

@media (min-width: 600px) {
    .contact {
        flex-direction: row;
        align-items: baseline;
    }
}

.footer {
    margin-top: 4em;
    font-size: 0.8em;
    max-width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-btn {
    color: var(--text-color);
}
